import { createAction } from 'redux-actions';

export const createRetailPlanRequest = createAction(
  'CREATE_RETAIL_PLAN_REQUEST'
);
export const createRetailPlanSuccess = createAction(
  'CREATE_RETAIL_PLAN_SUCCESS'
);
export const createRetailPlanFailure = createAction(
  'CREATE_RETAIL_PLAN_FAILURE'
);

export const getRetailPlanRequest = createAction('GET_RETAIL_PLAN_REQUEST');
export const getRetailPlanSuccess = createAction('GET_RETAIL_PLAN_SUCCESS');
export const getRetailPlanFailure = createAction('GET_RETAIL_PLAN_FAILURE');

export const getRetailPlanByIdRequest = createAction(
  'GET_RETAIL_PLAN_BY_ID_REQUEST'
);
export const getRetailPlanByIdSuccess = createAction(
  'GET_RETAIL_PLAN_BY_ID_SUCCESS'
);
export const getRetailPlanByIdFailure = createAction(
  'GET_RETAIL_PLAN_BY_ID_FAILURE'
);

export const updateRetailPlanRequest = createAction(
  'UPDATE_RETAIL_PLAN_REQUEST'
);
export const updateRetailPlanSuccess = createAction(
  'UPDATE_RETAIL_PLAN_SUCCESS'
);
export const updateRetailPlanFailure = createAction(
  'UPDATE_RETAIL_PLAN_FAILURE'
);

export const setPlansSequencesRequest = createAction(
  'SET_PLANS_SEQUENCES_REQUEST'
);
export const setPlansSequencesSuccess = createAction(
  'SET_PLANS_SEQUENCES_SUCCESS'
);
export const setPlansSequencesFailure = createAction(
  'SET_PLANS_SEQUENCES_FAILURE'
);

export const toggleRetailRequest = createAction('TOGGLE_RETAIL_REQUEST');
export const toggleRetailSuccess = createAction('TOGGLE_RETAIL_SUCCESS');
export const toggleRetailFailure = createAction('TOGGLE_RETAIL_FAILURE');

export const duplicatePlanRequest = createAction('DUPLICATE_PLAN_REQUEST');
export const duplicatePlanSuccess = createAction('DUPLICATE_PLAN_SUCCESS');
export const duplicatePlanFailure = createAction('DUPLICATE_PLAN_FAILURE');

export const makeDefaultRetailPlanRequest = createAction(
  'MAKE_DEFAULT_RETAIL_PLAN_REQUEST'
);
export const makeDefaultRetailPlanSuccess = createAction(
  'MAKE_DEFAULT_RETAIL_PLAN_SUCCESS'
);
export const makeDefaultRetailPlanFailure = createAction(
  'MAKE_DEFAULT_RETAIL_PLAN_FAILURE'
);

export const clearRetailPlanData = createAction('CLEAR_RETAIL_PLAN_DATA');

export const getRetailPlanSelectOptionsRequest = createAction(
  'GET_RETAIL_PLAN_SELECT_OPTIONS_REQUEST'
);
export const getRetailPlanSelectOptionsSuccess = createAction(
  'GET_RETAIL_PLAN_SELECT_OPTIONS_SUCCESS'
);
export const getRetailPlanSelectOptionsFailure = createAction(
  'GET_RETAIL_PLAN_SELECT_OPTIONS_FAILURE'
);

export const createTopUpRequest = createAction('CREATE_TOP_UP_REQUEST');
export const createTopUpSuccess = createAction('CREATE_TOP_UP_SUCCESS');
export const createTopUpFailure = createAction('CREATE_TOP_UP_FAILURE');

export const getTopUpListRequest = createAction('GET_TOP_UP_LIST_REQUEST');
export const getTopUpListSuccess = createAction('GET_TOP_UP_LIST_SUCCESS');
export const getTopUpListFailure = createAction('GET_TOP_UP_LIST_FAILURE');

export const getTopUpRequest = createAction('GET_TOP_UP_REQUEST');
export const getTopUpSuccess = createAction('GET_TOP_UP_SUCCESS');
export const getTopUpFailure = createAction('GET_TOP_UP_FAILURE');

export const clearTopUpData = createAction('CLEAR_TOP_UP_DATA');

export const updateTopUpRequest = createAction('UPDATE_TOP_UP_REQUEST');
export const updateTopUpSuccess = createAction('UPDATE_TOP_UP_SUCCESS');
export const updateTopUpFailure = createAction('UPDATE_TOP_UP_FAILURE');

export const duplicateTopUpRequest = createAction('DUPLICATE_TOP_UP_REQUEST');
export const duplicateTopUpSuccess = createAction('DUPLICATE_TOP_UP_SUCCESS');
export const duplicateTopUpFailure = createAction('DUPLICATE_TOP_UP_FAILURE');

export const deleteTopUpRequest = createAction('DELETE_TOP_UP_REQUEST');
export const deleteTopUpSuccess = createAction('DELETE_TOP_UP_SUCCESS');
export const deleteTopUpFailure = createAction('DELETE_TOP_UP_FAILURE');

export const toggleTopUpRequest = createAction('TOGGLE_TOP_UP_REQUEST');
export const toggleTopUpSuccess = createAction('TOGGLE_TOP_UP_SUCCESS');
export const toggleTopUpFailure = createAction('TOGGLE_TOP_UP_FAILURE');

export const updateRetailPlanGlobalSettingsRequest = createAction(
  'UPDATE_RETAIL_PLAN_GL_SETTINGS_REQUEST'
);
export const updateRetailPlanGlobalSettingsSuccess = createAction(
  'UPDATE_RETAIL_PLAN_GL_SETTINGS_SUCCESS'
);
export const updateRetailPlanGlobalSettingsFailure = createAction(
  'UPDATE_RETAIL_PLAN_GL_SETTINGS_FAILURE'
);
