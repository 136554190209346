import { handleActions } from 'redux-actions';

import {
  activateSIMMDNPlanFailure,
  activateSIMMDNPlanRequest,
  activateSIMMDNPlanSuccess,
  clearMdnSelect,
  createCarrierFailure,
  createCarrierRequest,
  createCarrierSuccess,
  deleteCarrierFailure,
  deleteCarrierRequest,
  deleteCarrierSuccess,
  deleteCarrierFileRequest,
  deleteCarrierFileSuccess,
  deleteCarrierFileFailure,
  enableIsFreeServiceFailure,
  enableIsFreeServiceRequest,
  enableIsFreeServiceSuccess,
  getCarriersListDataFailure,
  getCarriersListDataRequest,
  getCarriersListDataSuccess,
  getCarrierRequest,
  getCarrierSuccess,
  getCarrierFailure,
  getCarriersServicesFailure,
  getCarriersServicesRequest,
  getCarriersServicesSuccess,
  getExternalAPIsFailure,
  getExternalAPIsRequest,
  getExternalAPIsSuccess,
  getShippingCarriersSelectFailure,
  getShippingCarriersSelectRequest,
  getShippingCarriersSelectSuccess,
  toggleCarrierServiceFailure,
  toggleCarrierServiceRequest,
  toggleCarrierServiceSuccess,
  updateCarrierFailure,
  updateCarrierRequest,
  updateCarrierSuccess,
  getPortInRequest,
  getPortInSuccess,
  getPortInFailure,
  checkPortInStatusRequest,
  checkPortInStatusSuccess,
  checkPortInStatusFailure,
  clearCarriersListData,
  createManuallyPortInRequest,
  createManuallyPortInSuccess,
  createManuallyPortInFailure,
  cancelPortInRequest,
  cancelPortInSuccess,
  cancelPortInFailure,
  activateFWCRequest,
  activateFWCSuccess,
  activateFWCFailure,
  validateShippingRatesRequest,
  validateShippingRatesSuccess,
  validateShippingRatesFailure,
  clearRateOptions,
  changePlanRequest,
  changePlanSuccess,
  changePlanFailure,
  generateShippingPriceRequest,
  generateShippingPriceSuccess,
  generateShippingPriceFailure,
  updatePortRequest,
  updatePortSuccess,
  updatePortFailure,
  resubmitPortRequest,
  resubmitPortSuccess,
  resubmitPortFailure,
  activateHotlineRequest,
  activateHotlineSuccess,
  activateHotlineFailure,
  removeHotlineRequest,
  removeHotlineSuccess,
  removeHotlineFailure,
  refreshLineRequest,
  refreshLineSuccess,
  refreshLineFailure,
  portWithNewESNRequest,
  portWithNewESNSuccess,
  portWithNewESNFailure,
  changeMDNAtCarrierRequest,
  changeMDNAtCarrierSuccess,
  changeMDNAtCarrierFailure,
  cancelLinePortRequest,
  cancelLinePortSuccess,
  cancelLinePortFailure,
  changeCostPlanRequest,
  changeCostPlanSuccess,
  changeCostPlanFailure,
  getCDRReportRequest,
  getCDRReportSuccess,
  getCDRReportFailure,
  downloadCDRZipRequest,
  downloadCDRZipSuccess,
  downloadCDRZipFailure,
  uploadCDRRequest,
  uploadCDRSuccess,
  uploadCDRFailure,
  syncSubscriptionDatesRequest,
  syncSubscriptionDatesSuccess,
  syncSubscriptionDatesFailure,
} from './actions';

const initialState = {
  isCreatingCarrier: false,
  isCreatingCarrierSuccess: false,
  isCreatingCarrierFailure: false,
  carriersList: [],
  carriersCount: 0,
  successMessage: '',
  errorMessage: '',
  carrier: {},
  isGettingCarriersListData: true,
  isGettingCarriersListDataSuccess: false,
  isGettingCarriersListDataFailure: false,
  isGettingExternalCarriers: false,
  isGettingExternalCarriersSuccess: false,
  isGettingExternalCarriersFailure: false,
  externalAPIs: [],
  isGetCarrier: true,
  isGetCarrierSuccess: false,
  isGetCarrierFailure: false,
  carrierData: {},
  isDeletingCarrier: false,
  isDeletingCarrierSuccess: false,
  isDeletingCarrierFailure: false,
  isDeleteCarrierFile: false,
  isDeleteCarrierFileSuccess: false,
  isDeleteCarrierFileFailure: false,
  isUpdatingCarrier: false,
  isUpdatingCarrierSuccess: false,
  isUpdatingCarrierFailure: false,
  isGettingCarriersServices: true,
  isGettingCarriersServicesSuccess: false,
  isGettingCarriersServicesFailure: false,
  servicesList: [],
  servicesCount: 0,
  getShippingCarriersSelect: false,
  getShippingCarriersSelectSuccess: false,
  getShippingCarriersSelectFailure: false,
  shippingCarriersSelect: [],
  isToggleCarrierServiceSuccess: false,
  isToggleCarrierServiceFailure: false,
  isActivatingSIM: false,
  isActivatingSIMSuccess: false,
  isActivatingSIMFailure: false,
  isEnablingIsFree: false,
  isEnablingIsFreeSuccess: false,
  isEnablingIsFreeFailure: false,
  mdnSelect: [],
  isGetPortIn: false,
  isGetPortInSuccess: false,
  isGetPortInFailure: false,
  portIns: [],
  portInsCount: 0,
  isCheckingPortIn: false,
  isCheckingPortInSuccess: false,
  isCheckingPortInFailure: false,
  warningMessage: '',
  isCreatingManuallyPortIn: false,
  isCreatingManuallyPortInSuccess: false,
  isCreatingManuallyPortInFailure: false,
  isCancelingPortIn: false,
  isCancelingPortInSuccess: false,
  isCancelingPortInFailure: false,
  isActivatingWFC: false,
  isActivatingWFCSuccess: false,
  isActivatingWFCFailure: false,
  activatedLine: {},
  rates: [],
  isValidatingShippingRates: false,
  isValidatingShippingRatesSuccess: false,
  isValidatingShippingRatesFailure: false,
  isChangingPlan: false,
  isChangingPlanSuccess: false,
  isChangingPlanFailure: false,
  shippingPrice: {},
  isGeneratingShippingRate: false,
  isGeneratingShippingRateSuccess: false,
  isGeneratingShippingRateFailure: false,
  isUpdatingPort: false,
  isUpdatingPortSuccess: false,
  isUpdatingPortFailure: false,
  isResubmittingPort: false,
  isResubmittingPortSuccess: false,
  isResubmittingPortFailure: false,
  isActivatingHotline: false,
  isActivatingHotlineSuccess: false,
  isActivatingHotlineFailure: false,
  hotline: {},
  isRemovingHotline: false,
  isRemovingHotlineSuccess: false,
  isRemovingHotlineFailure: false,
  isRefreshingLine: false,
  isRefreshingLineSuccess: false,
  isRefreshingLineFailure: false,
  isPortingWithSameESN: false,
  isPortingWithSameESNSuccess: false,
  isPortingWithSameESNFailure: false,
  isChangingMDNAtCarrier: false,
  isChangingMDNAtCarrierSuccess: false,
  isChangingMDNAtCarrierFailure: false,
  isCancelingLinePort: false,
  isCancelingLinePortSuccess: false,
  isCancelingLinePortFailure: false,
  isChangingCostPlan: false,
  isChangingCostPlanSuccess: false,
  isChangingCostPlanFailure: false,
  isGettingCDRs: false,
  isGettingCDRsSuccess: false,
  isGettingCDRsFailure: false,
  cdrData: [],
  cdrCount: 0,
  cdrExport: [],
  isDownloadingCDR: false,
  isDownloadingCDRSuccess: false,
  isDownloadingCDRFailure: false,
  isSyncSubscriptionDates: false,
  isSyncSubscriptionDatesSuccess: false,
  isSyncSubscriptionDatesFailure: false,
};

const reducer = handleActions(
  {
    [createCarrierRequest]: (state) => ({
      ...state,
      isCreatingCarrier: true,
      isCreatingCarrierSuccess: false,
      isCreatingCarrierFailure: false,
    }),
    [createCarrierSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCarrier: false,
      isCreatingCarrierSuccess: true,
      successMessage: payload.message,
      carriersList: [payload.data, ...state.carriersList],
      carriersCount: state.carriersCount + 1,
    }),
    [createCarrierFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCarrier: false,
      isCreatingCarrierFailure: true,
      errorMessage: payload,
    }),
    [getExternalAPIsRequest]: (state) => ({
      ...state,
      isGettingExternalCarriers: true,
      isGettingExternalCarriersSuccess: false,
      isGettingExternalCarriersFailure: false,
    }),
    [getExternalAPIsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingExternalCarriers: false,
      isGettingExternalCarriersSuccess: true,
      successMessage: payload.message,
      externalAPIs: payload.data,
    }),
    [getExternalAPIsFailure]: (state, { payload }) => ({
      ...state,
      isGettingExternalCarriers: false,
      isGettingExternalCarriersFailure: true,
      errorMessage: payload,
    }),
    [getCarriersListDataRequest]: (state) => ({
      ...state,
      isGettingCarriersListData: true,
      isGettingCarriersListDataSuccess: false,
      isGettingCarriersListDataFailure: false,
    }),
    [getCarriersListDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCarriersListData: false,
      isGettingCarriersListDataSuccess: true,
      successMessage: payload.message,
      carriersList: payload.data.rows,
      carriersCount: payload.data.count,
    }),
    [getCarriersListDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingCarriersListData: false,
      isGettingCarriersListDataFailure: true,
      errorMessage: payload,
    }),

    [getCarrierRequest]: (state) => ({
      ...state,
      isGetCarrier: true,
      isGetCarrierSuccess: false,
      isGetCarrierFailure: false,
      carrierData: {},
    }),
    [getCarrierSuccess]: (state, { payload }) => ({
      ...state,
      isGetCarrier: false,
      isGetCarrierSuccess: true,
      carrierData: payload.data,
    }),
    [getCarrierFailure]: (state, { payload }) => ({
      ...state,
      isGetCarrier: false,
      isGetCarrierFailure: true,
      errorMessage: payload,
    }),

    [deleteCarrierRequest]: (state) => ({
      ...state,
      isDeletingCarrier: true,
      isDeletingCarrierSuccess: false,
      isDeletingCarrierFailure: false,
    }),
    [deleteCarrierSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingCarrier: false,
      isDeletingCarrierSuccess: true,
      successMessage: payload.message,
      carriersList: state.carriersList.filter((i) => i.id !== payload.data),
      carriersCount: state.carriersCount - 1,
    }),
    [deleteCarrierFailure]: (state, { payload }) => ({
      ...state,
      isDeletingCarrier: false,
      isDeletingCarrierFailure: true,
      errorMessage: payload,
    }),
    [deleteCarrierFileRequest]: (state) => ({
      ...state,
      isDeleteCarrierFile: true,
      isDeleteCarrierFileSuccess: false,
      isDeleteCarrierFileFailure: false,
    }),
    [deleteCarrierFileSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteCarrierFile: false,
      isDeleteCarrierFileSuccess: true,
      carrierData: {
        ...state.carrierData,
        Attachments: state.carrierData?.Attachments.filter(
          (item) => item.id !== payload.data
        ),
      },
      successMessage: payload.message,
    }),
    [deleteCarrierFileFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCarrierFile: false,
      isDeleteCarrierFileFailure: true,
      errorMessage: payload,
    }),
    [updateCarrierRequest]: (state) => ({
      ...state,
      isUpdatingCarrier: true,
      isUpdatingCarrierSuccess: false,
      isUpdatingCarrierFailure: false,
    }),
    [updateCarrierSuccess]: (state, { payload }) => {
      const carriersListCopy = [...state.carriersList];
      const index = carriersListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        carriersListCopy[index] = payload.data;
      }

      return {
        ...state,
        isUpdatingCarrier: false,
        isUpdatingCarrierSuccess: true,
        successMessage: payload.message,
        carriersList: carriersListCopy,
      };
    },
    [updateCarrierFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCarrier: false,
      isUpdatingCarrierFailure: true,
      errorMessage: payload,
    }),
    [getCarriersServicesRequest]: (state) => ({
      ...state,
      isGettingCarriersServices: true,
      isGettingCarriersServicesSuccess: false,
      isGettingCarriersServicesFailure: false,
    }),
    [getCarriersServicesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCarriersServices: false,
      isGettingCarriersServicesSuccess: true,
      successMessage: payload.message,
      servicesList: payload.data.rows,
      servicesCount: payload.data.count,
    }),
    [getCarriersServicesFailure]: (state, { payload }) => ({
      ...state,
      isGettingCarriersServices: false,
      isGettingCarriersServicesFailure: true,
      errorMessage: payload,
    }),
    [getShippingCarriersSelectRequest]: (state) => ({
      ...state,
      getShippingCarriersSelect: true,
      getShippingCarriersSelectFailure: false,
      getShippingCarriersSelectSuccess: false,
    }),
    [getShippingCarriersSelectSuccess]: (state, { payload }) => ({
      ...state,
      getShippingCarriersSelect: false,
      getShippingCarriersSelectSuccess: true,
      successMessage: payload.message,
      shippingCarriersSelect: payload.data,
    }),
    [getShippingCarriersSelectFailure]: (state, { payload }) => ({
      ...state,
      getShippingCarriersSelect: false,
      getShippingCarriersSelectFailure: true,
      errorMessage: payload,
    }),
    [toggleCarrierServiceRequest]: (state) => ({
      ...state,
      isToggleCarrierServiceSuccess: false,
      isToggleCarrierServiceFailure: false,
    }),
    [toggleCarrierServiceSuccess]: (state, { payload }) => {
      const servicesCopy = [...state.servicesList];
      const index = servicesCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        servicesCopy[index] = payload.data;
      }

      return {
        ...state,
        isToggleCarrierServiceSuccess: true,
        successMessage: payload.message,
        servicesList: servicesCopy,
      };
    },
    [toggleCarrierServiceFailure]: (state, { payload }) => ({
      ...state,
      isToggleCarrierServiceFailure: true,
      errorMessage: payload,
    }),
    [enableIsFreeServiceRequest]: (state) => ({
      ...state,
      isEnablingIsFree: true,
      isEnablingIsFreeSuccess: false,
      isEnablingIsFreeFailure: false,
    }),
    [enableIsFreeServiceSuccess]: (state, { payload }) => {
      let servicesListCopy = [...state.servicesList];
      const index = servicesListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        servicesListCopy = servicesListCopy.map((i) => ({
          ...i,
          isFree: false,
        }));
        servicesListCopy[index] = payload.data;
      }

      return {
        ...state,
        isEnablingIsFree: false,
        isEnablingIsFreeSuccess: true,
        successMessage: payload.message,
        servicesList: servicesListCopy,
      };
    },
    [enableIsFreeServiceFailure]: (state, { payload }) => ({
      ...state,
      isEnablingIsFree: false,
      isEnablingIsFreeFailure: true,
      errorMessage: payload,
    }),
    [activateSIMMDNPlanRequest]: (state) => ({
      ...state,
      warningMessage: '',
      isActivatingSIM: true,
      isActivatingSIMSuccess: false,
      isActivatingSIMFailure: false,
    }),
    [activateSIMMDNPlanSuccess]: (state, { payload }) => ({
      ...state,
      isActivatingSIM: false,
      isActivatingSIMSuccess: true,
      successMessage: payload.message,
      warningMessage: payload.warning,
      mdnSelect: payload.data.carrier.mdn,
    }),
    [activateSIMMDNPlanFailure]: (state, { payload }) => ({
      ...state,
      isActivatingSIM: false,
      isActivatingSIMFailure: true,
      errorMessage: payload,
      [clearMdnSelect]: (state) => ({
        ...state,
        mdnSelect: [],
      }),
    }),

    [getPortInRequest]: (state) => ({
      ...state,
      isGetPortIn: true,
      isGetPortInSuccess: false,
      isGetPortInFailure: false,
    }),
    [getPortInSuccess]: (state, { payload }) => ({
      ...state,
      isGetPortIn: false,
      isGetPortInSuccess: true,
      portIns: payload.data.rows,
      portInsCount: payload.data.count,
    }),
    [getPortInFailure]: (state, { payload }) => ({
      ...state,
      isGetPortIn: false,
      isGetPortInFailure: true,
      errorMessage: payload,
    }),
    [checkPortInStatusRequest]: (state) => ({
      ...state,
      isCheckingPortIn: true,
      isCheckingPortInSuccess: false,
      isCheckingPortInFailure: false,
    }),
    [checkPortInStatusSuccess]: (state, { payload }) => ({
      ...state,
      isCheckingPortIn: false,
      isCheckingPortInSuccess: true,
      successMessage: payload.message,
    }),
    [checkPortInStatusFailure]: (state, { payload }) => ({
      ...state,
      isCheckingPortIn: false,
      isCheckingPortInFailure: true,
      errorMessage: payload,
    }),
    [clearCarriersListData]: (state) => ({
      ...state,
      carriersList: [],
      carriersCount: 0,
    }),
    [createManuallyPortInRequest]: (state) => ({
      ...state,
      isCreatingManuallyPortIn: true,
      isCreatingManuallyPortInSuccess: false,
      isCreatingManuallyPortInFailure: false,
    }),
    [createManuallyPortInSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingManuallyPortIn: false,
      isCreatingManuallyPortInSuccess: true,
      successMessage: payload.message,
      portIns: [...state.portIns, payload.data],
      portInsCount: state.portInsCount + 1,
    }),
    [createManuallyPortInFailure]: (state, { payload }) => ({
      ...state,
      isCreatingManuallyPortIn: false,
      isCreatingManuallyPortInFailure: true,
      errorMessage: payload,
    }),
    [cancelPortInRequest]: (state) => ({
      ...state,
      isCancelingPortIn: true,
      isCancelingPortInSuccess: false,
      isCancelingPortInFailure: false,
    }),
    [cancelPortInSuccess]: (state, { payload }) => {
      const portinCopy = [...state.portIns];
      const index = portinCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        portinCopy[index] = payload.data;
      }

      return {
        ...state,
        isCancelingPortIn: false,
        isCancelingPortInSuccess: true,
        successMessage: payload.message,
        portIns: portinCopy,
      };
    },
    [cancelPortInFailure]: (state, { payload }) => ({
      ...state,
      isCancelingPortIn: false,
      isCancelingPortInFailure: true,
      errorMessage: payload,
    }),
    [activateFWCRequest]: (state) => ({
      ...state,
      isActivatingWFC: true,
      isActivatingWFCSuccess: false,
      isActivatingWFCFailure: false,
    }),
    [activateFWCSuccess]: (state, { payload }) => ({
      ...state,
      isActivatingWFC: false,
      isActivatingWFCSuccess: true,
      successMessage: payload.message,
      activatedLine: payload.data,
    }),
    [activateFWCFailure]: (state, { payload }) => ({
      ...state,
      isActivatingWFC: false,
      isActivatingWFCFailure: true,
      errorMessage: payload,
    }),
    [validateShippingRatesRequest]: (state) => ({
      ...state,
      isValidatingShippingRates: true,
      isValidatingShippingRatesSuccess: false,
      isValidatingShippingRatesFailure: false,
    }),
    [validateShippingRatesSuccess]: (state, { payload }) => ({
      ...state,
      isValidatingShippingRates: false,
      isValidatingShippingRatesSuccess: true,
      successMessage: payload.message,
      rates: payload.data,
    }),
    [validateShippingRatesFailure]: (state, { payload }) => ({
      ...state,
      isValidatingShippingRates: false,
      isValidatingShippingRatesFailure: true,
      errorMessage: payload,
    }),
    [clearRateOptions]: (state) => ({
      ...state,
      rates: [],
    }),
    [changePlanRequest]: (state) => ({
      ...state,
      isChangingPlan: true,
      isChangingPlanSuccess: false,
      isChangingPlanFailure: false,
    }),
    [changePlanSuccess]: (state, { payload }) => ({
      ...state,
      isChangingPlan: false,
      isChangingPlanSuccess: true,
      successMessage: payload.message,
    }),
    [changePlanFailure]: (state, { payload }) => ({
      ...state,
      isChangingPlan: false,
      isChangingPlanFailure: true,
      errorMessage: payload,
    }),
    [generateShippingPriceRequest]: (state) => ({
      ...state,
      isGeneratingShippingRate: true,
      isGeneratingShippingRateSuccess: false,
      isGeneratingShippingRateFailure: false,
    }),
    [generateShippingPriceSuccess]: (state, { payload }) => ({
      ...state,
      isGeneratingShippingRate: false,
      isGeneratingShippingRateSuccess: true,
      successMessage: payload.message,
      shippingPrice: payload.data,
    }),
    [generateShippingPriceFailure]: (state, { payload }) => ({
      ...state,
      isGeneratingShippingRate: false,
      isGeneratingShippingRateFailure: true,
      errorMessage: payload,
    }),
    [updatePortRequest]: (state) => ({
      ...state,
      isUpdatingPort: true,
      isUpdatingPortSuccess: false,
      isUpdatingPortFailure: false,
    }),
    [updatePortSuccess]: (state, { payload }) => {
      const portsCopy = [...state.portIns];

      const index = portsCopy.findIndex((port) => port.id === payload.data.id);
      if (index !== -1) {
        portsCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingPort: false,
        isUpdatingPortSuccess: true,
        successMessage: payload.message,
        portIns: portsCopy,
      };
    },
    [updatePortFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingPort: false,
      isUpdatingPortFailure: true,
      errorMessage: payload,
    }),
    [resubmitPortRequest]: (state) => ({
      ...state,
      isResubmittingPort: true,
      isResubmittingPortSuccess: false,
      isResubmittingPortFailure: false,
    }),
    [resubmitPortSuccess]: (state, { payload }) => {
      const portInsCopy = [...state.portIns];
      const index = portInsCopy.findIndex(
        (port) => port.id === payload.data.id
      );
      if (index !== -1) {
        portInsCopy[index] = payload.data;
      }
      return {
        ...state,
        isResubmittingPort: false,
        isResubmittingPortSuccess: true,
        successMessage: payload.message,
        portIns: portInsCopy,
      };
    },
    [resubmitPortFailure]: (state, { payload }) => ({
      ...state,
      isResubmittingPort: false,
      isResubmittingPortFailure: true,
      errorMessage: payload,
    }),
    [activateHotlineRequest]: (state) => ({
      ...state,
      isActivatingHotline: true,
      isActivatingHotlineSuccess: false,
      isActivatingHotlineFailure: false,
    }),
    [activateHotlineSuccess]: (state, { payload }) => ({
      ...state,
      isActivatingHotline: false,
      isActivatingHotlineSuccess: true,
      successMessage: payload.message,
      hotline: payload.data,
    }),
    [activateHotlineFailure]: (state, { payload }) => ({
      ...state,
      isActivatingHotline: false,
      isActivatingHotlineFailure: true,
      errorMessage: payload,
    }),
    [removeHotlineRequest]: (state) => ({
      ...state,
      isRemovingHotline: true,
      isRemovingHotlineSuccess: false,
      isRemovingHotlineFailure: false,
    }),
    [removeHotlineSuccess]: (state, { payload }) => ({
      ...state,
      isRemovingHotline: false,
      isRemovingHotlineSuccess: true,
      successMessage: payload.message,
      hotline: payload.data,
    }),
    [removeHotlineFailure]: (state, { payload }) => ({
      ...state,
      isRemovingHotline: false,
      isRemovingHotlineFailure: true,
      errorMessage: payload,
    }),
    [refreshLineRequest]: (state) => ({
      ...state,
      isRefreshingLine: true,
      isRefreshingLineSuccess: false,
      isRefreshingLineFailure: false,
    }),
    [refreshLineSuccess]: (state, { payload }) => ({
      ...state,
      isRefreshingLine: false,
      isRefreshingLineSuccess: true,
      successMessage: payload.message,
    }),
    [refreshLineFailure]: (state, { payload }) => ({
      ...state,
      isRefreshingLine: false,
      isRefreshingLineFailure: true,
      errorMessage: payload,
    }),
    [portWithNewESNRequest]: (state) => ({
      ...state,
      isPortingWithSameESN: true,
      isPortingWithSameESNSuccess: false,
      isPortingWithSameESNFailure: false,
    }),
    [portWithNewESNSuccess]: (state, { payload }) => ({
      ...state,
      isPortingWithSameESN: false,
      isPortingWithSameESNSuccess: true,
      successMessage: payload.message,
    }),
    [portWithNewESNFailure]: (state, { payload }) => ({
      ...state,
      isPortingWithSameESN: false,
      isPortingWithSameESNFailure: true,
      errorMessage: payload,
    }),
    [changeMDNAtCarrierRequest]: (state) => ({
      ...state,
      isChangingMDNAtCarrier: true,
      isChangingMDNAtCarrierSuccess: false,
      isChangingMDNAtCarrierFailure: false,
    }),
    [changeMDNAtCarrierSuccess]: (state, { payload }) => ({
      ...state,
      isChangingMDNAtCarrier: false,
      isChangingMDNAtCarrierSuccess: true,
      successMessage: payload.message,
    }),
    [changeMDNAtCarrierFailure]: (state, { payload }) => ({
      ...state,
      isChangingMDNAtCarrier: false,
      isChangingMDNAtCarrierFailure: true,
      errorMessage: payload,
    }),
    [cancelLinePortRequest]: (state) => ({
      ...state,
      isCancelingLinePort: true,
      isCancelingLinePortSuccess: false,
      isCancelingLinePortFailure: false,
    }),
    [cancelLinePortSuccess]: (state, { payload }) => ({
      ...state,
      isCancelingLinePort: false,
      isCancelingLinePortSuccess: true,
      successMessage: payload.message,
    }),
    [cancelLinePortFailure]: (state, { payload }) => ({
      ...state,
      isCancelingLinePort: false,
      isCancelingLinePortFailure: true,
      errorMessage: payload,
    }),
    [changeCostPlanRequest]: (state) => ({
      ...state,
      isChangingCostPlan: true,
      isChangingCostPlanSuccess: false,
      isChangingCostPlanFailure: false,
    }),
    [changeCostPlanSuccess]: (state, { payload }) => ({
      ...state,
      isChangingCostPlan: false,
      isChangingCostPlanSuccess: true,
      successMessage: payload.message,
    }),
    [changeCostPlanFailure]: (state, { payload }) => ({
      ...state,
      isChangingCostPlan: false,
      isChangingCostPlanFailure: true,
      errorMessage: payload,
    }),
    [getCDRReportRequest]: (state) => ({
      ...state,
      isGettingCDRs: true,
      isGettingCDRsSuccess: false,
      isGettingCDRsFailure: false,
    }),
    [getCDRReportSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCDRs: false,
      isGettingCDRsSuccess: true,
      successMessage: payload.message,
      cdrData: payload.data.rows,
      cdrCount: payload.data.count,
      cdrExport: payload.data.cdrExport,
    }),
    [getCDRReportFailure]: (state, { payload }) => ({
      ...state,
      isGettingCDRs: false,
      isGettingCDRsFailure: true,
      errorMessage: payload,
    }),
    [downloadCDRZipRequest]: (state) => ({
      ...state,
      isDownloadingCDR: true,
      isDownloadingCDRSuccess: false,
      isDownloadingCDRFailure: false,
    }),
    [downloadCDRZipSuccess]: (state, { payload }) => ({
      ...state,
      isDownloadingCDR: false,
      isDownloadingCDRSuccess: true,
      successMessage: payload.message,
    }),
    [downloadCDRZipFailure]: (state, { payload }) => ({
      ...state,
      isDownloadingCDR: false,
      isDownloadingCDRFailure: true,
      errorMessage: payload,
    }),
    [uploadCDRRequest]: (state) => ({
      ...state,
      isUploadingCdr: true,
      isUploadingCdrSuccess: false,
      isUploadingCdrFailure: false,
    }),
    [uploadCDRSuccess]: (state, { payload }) => ({
      ...state,
      isUploadingCdr: false,
      isUploadingCdrSuccess: true,
      successMessage: payload.message,
    }),
    [uploadCDRFailure]: (state, { payload }) => ({
      ...state,
      isUploadingCdr: false,
      isUploadingCdrFailure: true,
      errorMessage: payload,
    }),
    [syncSubscriptionDatesRequest]: (state) => ({
      ...state,
      isSyncSubscriptionDates: true,
      isSyncSubscriptionDatesSuccess: false,
      isSyncSubscriptionDatesFailure: false,
    }),
    [syncSubscriptionDatesSuccess]: (state, { payload }) => ({
      ...state,
      isSyncSubscriptionDates: false,
      isSyncSubscriptionDatesSuccess: true,
      successMessage: payload.message,
    }),
    [syncSubscriptionDatesFailure]: (state, { payload }) => ({
      ...state,
      isSyncSubscriptionDates: false,
      isSyncSubscriptionDatesFailure: false,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
