import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

import {
  activateOtherOrderIMEIFailure,
  activateOtherOrderIMEIRequest,
  activateOtherOrderIMEISuccess,
  cancelInventoryRequestFailure,
  cancelInventoryRequestRequest,
  cancelInventoryRequestSuccess,
  checkingRatesFailure,
  checkingRatesRequest,
  checkingRatesSuccess,
  createAgentOrderFailure,
  createAgentOrderRequest,
  createAgentOrderSuccess,
  estimateShippingRateFailure,
  estimateShippingRateRequest,
  estimateShippingRateSuccess,
  findCustomerOrderFailure,
  findCustomerOrderRequest,
  findCustomerOrderSuccess,
  fulfillCustomerOrderFailure,
  fulfillCustomerOrderRequest,
  fulfillCustomerOrderSuccess,
  fulfillHandoverOrderRequest,
  fulfillHandoverOrderSuccess,
  fulfillHandoverOrderFailure,
  fulfillOrderFailure,
  fulfillOrderRequest,
  fulfillOrderSuccess,
  getCarrierServicesSelectFailure,
  getCarrierServicesSelectRequest,
  getCarrierServicesSelectSuccess,
  getCustomerOrdersFailure,
  getCustomerOrdersRequest,
  getCustomerOrdersSuccess,
  getInventoryRequestFailure,
  getInventoryRequestListFailure,
  getInventoryRequestListRequest,
  getInventoryRequestListSuccess,
  getInventoryRequestRequest,
  getInventoryRequestSuccess,
  getOrderHistoryFailure,
  getOrderHistoryRequest,
  getOrderHistorySuccess,
  getSimCardWithIMEIFailure,
  getSimCardWithIMEIRequest,
  getSimCardWithIMEISuccess,
  rejectInventoryOrdersFailure,
  rejectInventoryOrdersRequest,
  rejectInventoryOrdersSuccess,
  shipCustomerOrderFailure,
  shipCustomerOrderRequest,
  shipCustomerOrderSuccess,
  trackShippingFailure,
  trackShippingRequest,
  trackShippingSuccess,
  updateCustomerShippingAddressFailure,
  updateCustomerShippingAddressRequest,
  updateCustomerShippingAddressSuccess,
  finishCustomerOrderSuccess,
  finishCustomerOrderFailure,
  finishCustomerOrderRequest,
  handoverAndFulfillRequest,
  handoverAndFulfillSuccess,
  handoverAndFulfillFailure,
  handoverQuicklySuccess,
  handoverQuicklyFailure,
  handoverQuicklyRequest,
  getOrderLineWithDataSuccess,
  getOrderLineWithDataFailure,
  getOrderLineWithDataRequest,
  updateShippingRateFailure,
  updateShippingRateRequest,
  updateShippingRateSuccess,
  resubmitOrderSuccess,
  resubmitOrderFailure,
  resubmitOrderRequest,
  getInvoicesForLineRefundSuccess,
  getInvoicesForLineRefundFailure,
  getInvoicesForLineRefundRequest,
  refundLineAmountSuccess,
  refundLineAmountFailure,
  refundLineAmountRequest,
  updateOrderSuccess,
  updateOrderFailure,
  updateOrderRequest,
  deactivateLineSuccess,
  deactivateLineFailure,
  deactivateLineRequest,
  reactivateLineSuccess,
  reactivateLineFailure,
  reactivateLineRequest,
  removeLinePortSuccess,
  removeLinePortFailure,
  removeLinePortRequest,
  keepOldNumberSuccess,
  keepOldNumberFailure,
  keepOldNumberRequest,
  getHotlinesSuccess,
  getHotlinesFailure,
  getHotlinesRequest,
  getDeactivatedLinesSuccess,
  getDeactivatedLinesFailure,
  getDeactivatedLinesRequest,
  removeLineSuccess,
  removeLineFailure,
  removeLineRequest,
  recreateBillingSuccess,
  recreateBillingFailure,
  recreateBillingRequest,
  createCombinedBillingSuccess,
  createCombinedBillingFailure,
  createCombinedBillingRequest,
  getCanceledLinesSuccess,
  getCanceledLinesFailure,
  getCanceledLinesRequest,
  abandonedAndFailedOrdersSuccess,
  abandonedAndFailedOrdersFailure,
  abandonedAndFailedOrdersRequest,
  activeLinesWithNoSubscriptionSuccess,
  activeLinesWithNoSubscriptionFailure,
  activeLinesWithNoSubscriptionRequest,
  getCustomerOrdersRequestById,
  getCustomerOrdersByIdSuccess,
  getCustomerOrdersByIdFailure,
  getDuplicatedCustomerAccountsSuccess,
  getDuplicatedCustomerAccountsFailure,
  getDuplicatedCustomerAccountsRequest,
} from './actions';

const URL = `${config.TENANT}/e-commerce/orders`;
const URL_CARRIER = `${config.TENANT}/carriers/shipping`;
function* inventoryRequestList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/requests-list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getInventoryRequestListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for inventoryRequestList, error', e);
    if (e?.response?.data) {
      yield put(getInventoryRequestListFailure(catchResponseMessages(e)));
    }
  }
}

function* rejectInventoryRequest({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/reject-inventory-request/${payload}`)
    );
    if (response?.status === 200) {
      yield put(rejectInventoryOrdersSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for rejectInventoryRequest, error', e);
    if (e?.response?.data) {
      yield put(rejectInventoryOrdersFailure(catchResponseMessages(e)));
    }
  }
}

function* fulfillRequest({ payload }) {
  try {
    const { requestId, ...data } = payload;
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/fulfill-inventory-request/${requestId}`,
        data
      )
    );
    if (response?.status === 200) {
      yield put(fulfillOrderSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for fulfillRequest, error', e);
    if (e?.response?.data) {
      yield put(fulfillOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* cancelInventoryRequest({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/cancel-inventory-request/${payload}`)
    );
    if (response?.status === 200) {
      yield put(cancelInventoryRequestSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for cancelInventoryRequest, error', e);
    if (e?.response?.data) {
      yield put(cancelInventoryRequestFailure(catchResponseMessages(e)));
    }
  }
}

function* createAgentOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-agent-order`, payload)
    );
    if (response?.status === 200) {
      yield put(createAgentOrderSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createAgentOrder, error', e);
    if (e?.response?.data) {
      yield put(createAgentOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* carrierServicesSelect({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_CARRIER}/services-select${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCarrierServicesSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for carrierServicesSelect, error', e);
    if (e?.response?.data) {
      yield put(getCarrierServicesSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* orderHistory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/orders-history${payload}`)
    );
    if (response?.status === 200) {
      yield put(getOrderHistorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for orderHistory, error', e);
    if (e?.response?.data) {
      yield put(getOrderHistoryFailure(catchResponseMessages(e)));
    }
  }
}

function* checkRates({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/check-rates/${payload.carrierId}`,
        payload.data
      )
    );
    if (response?.status === 200) {
      yield put(checkingRatesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for checkRates, error', e);
    if (e?.response?.data) {
      yield put(checkingRatesFailure(catchResponseMessages(e)));
    }
  }
}

function* trackShipment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/track-order/${payload.tracking_number}/${payload.carrier_code}`
      )
    );
    if (response?.status === 200) {
      yield put(trackShippingSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for trackShipment, error', e);
    if (e?.response?.data) {
      yield put(trackShippingFailure(catchResponseMessages(e)));
    }
  }
}

function* getInventoryRequest({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/inventory-request/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getInventoryRequestSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getInventoryRequest, error', e);
    if (e?.response?.data) {
      yield put(getInventoryRequestFailure(catchResponseMessages(e)));
    }
  }
}

function* getCustomerOrders({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/customer-orders${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCustomerOrdersSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomerOrders, error', e);
    if (e?.response?.data) {
      yield put(getCustomerOrdersFailure(catchResponseMessages(e)));
    }
  }
}
function* getCustomerOrdersById({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/customer-orders${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCustomerOrdersByIdSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomerOrders, error', e);
    if (e?.response?.data) {
      yield put(getCustomerOrdersByIdFailure(catchResponseMessages(e)));
    }
  }
}

function* findCustomerOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/customer-order/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findCustomerOrderSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findCustomerOrder, error', e);
    if (e?.response?.data) {
      yield put(findCustomerOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* updateShippingAddress({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/shipping-address/${payload.customerId}/${payload.orderId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateCustomerShippingAddressSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateShippingAddress, error`, e);
    if (e.response?.data) {
      yield put(updateCustomerShippingAddressFailure(catchResponseMessages(e)));
    }
  }
}

function* estimatingRate({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/estimate-rate/${payload.customerId}/${payload.orderId}`
      )
    );

    if (response?.status === 200) {
      yield put(estimateShippingRateSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for estimatingRate, error`, e);
    if (e.response?.data) {
      yield put(estimateShippingRateFailure(catchResponseMessages(e)));
    }
  }
}

function* fulfillCustomerOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/fulfill-customer-order/${payload.customerId}/${payload.orderId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(fulfillCustomerOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for fulfillCustomerOrder, error`, e);
    if (e.response?.data) {
      yield put(fulfillCustomerOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* fulfillHandoverOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/fulfill-handover-order/${payload.customerId}/${payload.orderId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(fulfillHandoverOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for fulfillCustomerOrder, error`, e);
    if (e.response?.data) {
      yield put(fulfillHandoverOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* activateOtherLineImei({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/activate-other-line-imei`, payload)
    );

    if (response?.status === 200) {
      yield put(activateOtherOrderIMEISuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for activateOtherLineImei, error`, e);
    if (e.response?.data) {
      yield put(activateOtherOrderIMEIFailure(catchResponseMessages(e)));
    }
  }
}

function* shipCustomerOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/ship-customer-order`, payload)
    );

    if (response?.status === 200) {
      yield put(shipCustomerOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for shipCustomerOrder, error`, e);
    if (e.response?.data) {
      yield put(shipCustomerOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* getSimCardWithIMEI({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/sim-card-imei`, payload)
    );

    if (response?.status === 200) {
      yield put(getSimCardWithIMEISuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getSimCardWithIMEI, error`, e);
    if (e.response?.data) {
      yield put(getSimCardWithIMEIFailure(catchResponseMessages(e)));
    }
  }
}

function* finishCustomerOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/order-finish/${payload.customerId}/${payload.orderId}`
      )
    );

    if (response?.status === 200) {
      yield put(finishCustomerOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for finishCustomerOrder, error`, e);
    if (e.response?.data) {
      yield put(finishCustomerOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* handoverAndFulfill({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/handover-and-fulfill/${payload.customerId}/${payload.orderId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(handoverAndFulfillSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for handoverAndFulfill, error`, e);
    if (e.response?.data) {
      yield put(handoverAndFulfillFailure(catchResponseMessages(e)));
    }
  }
}

function* handoverQuickly({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/handover-quickly/${payload.customerId}/${payload.orderId}`
      )
    );

    if (response?.status === 200) {
      yield put(handoverQuicklySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for handoverQuickly, error`, e);
    if (e.response?.data) {
      yield put(handoverQuicklyFailure(catchResponseMessages(e)));
    }
  }
}
function* getOrderLines({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/order-lines`)
    );

    if (response?.status === 200) {
      yield put(getOrderLineWithDataSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getOrderLines, error`, e);
    if (e.response?.data) {
      yield put(getOrderLineWithDataFailure(catchResponseMessages(e)));
    }
  }
}

function* updateRate({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/update-rate/${payload.orderId}/${payload.customerId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateShippingRateSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateRate, error`, e);
    if (e?.response?.data) {
      yield put(updateShippingRateFailure(catchResponseMessages(e)));
    }
  }
}
function* resubmitOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/resubmit-order/${payload.orderId}/${payload.customerId}/${payload.subId}`
      )
    );

    if (response?.status === 200) {
      yield put(resubmitOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for resubmitOrder, error`, e);
    if (e?.response?.data) {
      yield put(resubmitOrderFailure(catchResponseMessages(e)));
    }
  }
}
function* getInvoicesForRefund({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/invoices-for-refund/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(getInvoicesForLineRefundSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getInvoicesForRefund, error`, e);
    if (e?.response?.data) {
      yield put(getInvoicesForLineRefundFailure(catchResponseMessages(e)));
    }
  }
}
function* refundLineAmount({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/line-amount-refund`, payload)
    );

    if (response?.status === 200) {
      yield put(refundLineAmountSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for refundLineAmount, error`, e);
    if (e?.response?.data) {
      yield put(refundLineAmountFailure(catchResponseMessages(e)));
    }
  }
}
function* updateOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/update-order/${payload.historyId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateOrderSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateOrder, error`, e);
    if (e?.response?.data) {
      yield put(updateOrderFailure(catchResponseMessages(e)));
    }
  }
}
function* deactivateLine({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/deactivate-line/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(deactivateLineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deactivateLine, error`, e);
    if (e?.response?.data) {
      yield put(deactivateLineFailure(catchResponseMessages(e)));
    }
  }
}

function* reactivateLine({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/reactivate-line/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(reactivateLineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for reactivateLine, error`, e);
    if (e?.response?.data) {
      yield put(reactivateLineFailure(catchResponseMessages(e)));
    }
  }
}

function* recreateBilling({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/recreate-billing/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(recreateBillingSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for recreateBilling, error`, e);
    if (e?.response?.data) {
      yield put(recreateBillingFailure(catchResponseMessages(e)));
    }
  }
}

function* removeLinePort({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/remove-line-port/${payload.id}/${payload.portId}`
      )
    );

    if (response?.status === 200) {
      yield put(removeLinePortSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for reactivateLine, error`, e);
    if (e?.response?.data) {
      yield put(removeLinePortFailure(catchResponseMessages(e)));
    }
  }
}

function* keepOldNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/keep-old-number/${payload.lineId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(keepOldNumberSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for keepOldNumber, error`, e);
    if (e?.response?.data) {
      yield put(keepOldNumberFailure(catchResponseMessages(e)));
    }
  }
}

function* getHotlines({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/hotlines${payload}`)
    );

    if (response?.status === 200) {
      yield put(getHotlinesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getHotlines, error`, e);
    if (e?.response?.data) {
      yield put(getHotlinesFailure(catchResponseMessages(e)));
    }
  }
}

function* getDeactivatedLines({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/deactivated-lines${payload}`)
    );

    if (response?.status === 200) {
      yield put(getDeactivatedLinesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getDeactivatedLines, error`, e);
    if (e?.response?.data) {
      yield put(getDeactivatedLinesFailure(catchResponseMessages(e)));
    }
  }
}

function* getCanceledLines({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/canceled-lines${payload}`)
    );

    if (response?.status === 200) {
      yield put(getCanceledLinesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCanceledLines, error`, e);
    if (e?.response?.data) {
      yield put(getCanceledLinesFailure(catchResponseMessages(e)));
    }
  }
}

function* removeLine({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/remove-line/${payload.lineId}/${payload.orderId}`
      )
    );

    if (response?.status === 200) {
      yield put(removeLineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for removeLine, error`, e);
    if (e?.response?.data) {
      yield put(removeLineFailure(catchResponseMessages(e)));
    }
  }
}

function* createCombinedBilling({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-combined-billing/${payload.orderId}`)
    );

    if (response?.status === 200) {
      yield put(createCombinedBillingSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createCombinedBilling, error`, e);
    if (e?.response?.data) {
      yield put(createCombinedBillingFailure(catchResponseMessages(e)));
    }
  }
}

function* abandonedAndFailedOrders({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/abandoned-failed-orders${payload}`)
    );

    if (response?.status === 200) {
      yield put(abandonedAndFailedOrdersSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for abandonedAndFailedOrders, error`, e);
    if (e?.response?.data) {
      yield put(abandonedAndFailedOrdersFailure(catchResponseMessages(e)));
    }
  }
}

function* activeLinesWithNoSubscription({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/active-lines-with-no-subscription${payload}`)
    );

    if (response?.status === 200) {
      yield put(activeLinesWithNoSubscriptionSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for activeLinesWithNoSubscription, error`, e);
    if (e?.response?.data) {
      yield put(activeLinesWithNoSubscriptionFailure(catchResponseMessages(e)));
    }
  }
}

function* getDuplicatedCustomers({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/duplicated-customers`, payload)
    );

    if (response?.status === 200) {
      yield put(getDuplicatedCustomerAccountsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getDuplicatedCustomers, error`, e);
    if (e?.response?.data) {
      yield put(getDuplicatedCustomerAccountsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getInventoryRequestListRequest, inventoryRequestList);
  yield takeLatest(rejectInventoryOrdersRequest, rejectInventoryRequest);
  yield takeLatest(fulfillOrderRequest, fulfillRequest);
  yield takeLatest(cancelInventoryRequestRequest, cancelInventoryRequest);
  yield takeLatest(createAgentOrderRequest, createAgentOrder);
  yield takeLatest(getCarrierServicesSelectRequest, carrierServicesSelect);
  yield takeLatest(getOrderHistoryRequest, orderHistory);
  yield takeLatest(checkingRatesRequest, checkRates);
  yield takeLatest(trackShippingRequest, trackShipment);
  yield takeLatest(getInventoryRequestRequest, getInventoryRequest);
  yield takeLatest(getCustomerOrdersRequest, getCustomerOrders);
  yield takeLatest(getCustomerOrdersRequestById, getCustomerOrdersById);
  yield takeLatest(findCustomerOrderRequest, findCustomerOrder);
  yield takeLatest(updateCustomerShippingAddressRequest, updateShippingAddress);
  yield takeLatest(estimateShippingRateRequest, estimatingRate);
  yield takeLatest(fulfillCustomerOrderRequest, fulfillCustomerOrder);
  yield takeLatest(fulfillHandoverOrderRequest, fulfillHandoverOrder);
  yield takeLatest(activateOtherOrderIMEIRequest, activateOtherLineImei);
  yield takeLatest(shipCustomerOrderRequest, shipCustomerOrder);
  yield takeLatest(getSimCardWithIMEIRequest, getSimCardWithIMEI);
  yield takeLatest(finishCustomerOrderRequest, finishCustomerOrder);
  yield takeLatest(handoverAndFulfillRequest, handoverAndFulfill);
  yield takeLatest(handoverQuicklyRequest, handoverQuickly);
  yield takeLatest(getOrderLineWithDataRequest, getOrderLines);
  yield takeLatest(updateShippingRateRequest, updateRate);
  yield takeLatest(resubmitOrderRequest, resubmitOrder);
  yield takeLatest(getInvoicesForLineRefundRequest, getInvoicesForRefund);
  yield takeLatest(refundLineAmountRequest, refundLineAmount);
  yield takeLatest(updateOrderRequest, updateOrder);
  yield takeLatest(deactivateLineRequest, deactivateLine);
  yield takeLatest(reactivateLineRequest, reactivateLine);
  yield takeLatest(removeLinePortRequest, removeLinePort);
  yield takeLatest(keepOldNumberRequest, keepOldNumber);
  yield takeLatest(getHotlinesRequest, getHotlines);
  yield takeLatest(getDeactivatedLinesRequest, getDeactivatedLines);
  yield takeLatest(removeLineRequest, removeLine);
  yield takeLatest(recreateBillingRequest, recreateBilling);
  yield takeLatest(createCombinedBillingRequest, createCombinedBilling);
  yield takeLatest(getCanceledLinesRequest, getCanceledLines);
  yield takeLatest(abandonedAndFailedOrdersRequest, abandonedAndFailedOrders);
  yield takeLatest(
    getDuplicatedCustomerAccountsRequest,
    getDuplicatedCustomers
  );
  yield takeLatest(
    activeLinesWithNoSubscriptionRequest,
    activeLinesWithNoSubscription
  );
}
