import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createRetailPlanRequest,
  createRetailPlanSuccess,
  createRetailPlanFailure,
  getRetailPlanRequest,
  getRetailPlanSuccess,
  getRetailPlanFailure,
  getRetailPlanByIdRequest,
  getRetailPlanByIdSuccess,
  getRetailPlanByIdFailure,
  updateRetailPlanRequest,
  updateRetailPlanSuccess,
  updateRetailPlanFailure,
  setPlansSequencesRequest,
  setPlansSequencesSuccess,
  setPlansSequencesFailure,
  toggleRetailRequest,
  toggleRetailSuccess,
  toggleRetailFailure,
  duplicatePlanSuccess,
  duplicatePlanFailure,
  duplicatePlanRequest,
  makeDefaultRetailPlanSuccess,
  makeDefaultRetailPlanFailure,
  makeDefaultRetailPlanRequest,
  getRetailPlanSelectOptionsSuccess,
  getRetailPlanSelectOptionsFailure,
  getRetailPlanSelectOptionsRequest,
  createTopUpSuccess,
  createTopUpFailure,
  createTopUpRequest,
  getTopUpListSuccess,
  getTopUpListFailure,
  getTopUpListRequest,
  getTopUpSuccess,
  getTopUpFailure,
  getTopUpRequest,
  updateTopUpSuccess,
  updateTopUpFailure,
  updateTopUpRequest,
  duplicateTopUpRequest,
  duplicateTopUpSuccess,
  duplicateTopUpFailure,
  deleteTopUpSuccess,
  deleteTopUpFailure,
  deleteTopUpRequest,
  updateRetailPlanGlobalSettingsSuccess,
  updateRetailPlanGlobalSettingsFailure,
  updateRetailPlanGlobalSettingsRequest,
  toggleTopUpRequest,
  toggleTopUpSuccess,
  toggleTopUpFailure,
} from './actions';

const URL = `${config.TENANT}/settings`;

function* createRetailPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-retail-plan`, payload)
    );
    if (response?.status === 200) {
      yield put(createRetailPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createRetailPlan, error', e);
    if (e?.response?.data) {
      yield put(createRetailPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getRetailPlan({ payload = '' }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/retail-plan${payload}`)
    );
    if (response?.status === 200) {
      yield put(getRetailPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getRetailPlan, error', e);
    if (e?.response?.data) {
      yield put(getRetailPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getRetailPlanById({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/retail-plan/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getRetailPlanByIdSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getRetailPlanById, error', e);
    if (e?.response?.data) {
      yield put(getRetailPlanByIdFailure(catchResponseMessages(e)));
    }
  }
}

function* updateRetailPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-retail-plan/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateRetailPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateRetailPlan, error', e);
    if (e?.response?.data) {
      yield put(updateRetailPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* setPlansSequences({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/set-plan-sequences`, payload)
    );

    if (response?.status === 200) {
      yield put(
        setPlansSequencesSuccess({ ...response.data, data: payload || [] })
      );
    }
  } catch (e) {
    console.log('Catch for setPlansSequences, error', e);
    if (e?.response?.data) {
      yield put(setPlansSequencesFailure(catchResponseMessages(e)));
    }
  }
}

function* toggleRetailPlanEnabled({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/retail-plan-toggle/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(toggleRetailSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateRetailPlan, error', e);
    if (e?.response?.data) {
      yield put(toggleRetailFailure(catchResponseMessages(e)));
    }
  }
}

function* duplicatePlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/duplicate-plan`, payload)
    );
    if (response?.status === 200) {
      yield put(duplicatePlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for duplicatePlan, error', e);
    if (e?.response?.data) {
      yield put(duplicatePlanFailure(catchResponseMessages(e)));
    }
  }
}

function* makePlanDefault({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/make-plan-default/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(makeDefaultRetailPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for makePlanDefault, error', e);
    if (e?.response?.data) {
      yield put(makeDefaultRetailPlanFailure(catchResponseMessages(e)));
    }
  }
}
function* planSelectOptions({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/retail-select-options${payload.query}`)
    );
    if (response?.status === 200) {
      yield put(getRetailPlanSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for makePlanDefault, error', e);
    if (e?.response?.data) {
      yield put(getRetailPlanSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* createTopUp({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-top-up`, payload)
    );
    if (response?.status === 200) {
      yield put(createTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createTopUp, error', e);
    if (e?.response?.data) {
      yield put(createTopUpFailure(catchResponseMessages(e)));
    }
  }
}

function* topUpList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/top-up-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTopUpListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for topUpList, error', e);
    if (e?.response?.data) {
      yield put(getTopUpListFailure(catchResponseMessages(e)));
    }
  }
}

function* topUpData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-top-up/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for topUpData, error', e);
    if (e?.response?.data) {
      yield put(getTopUpFailure(catchResponseMessages(e)));
    }
  }
}

function* updateTopUp({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/top-up/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(updateTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateTopUp, error', e);
    if (e?.response?.data) {
      yield put(updateTopUpFailure(catchResponseMessages(e)));
    }
  }
}

function* duplicateTopUp({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/duplicate-top-up/${payload}`)
    );
    if (response?.status === 200) {
      yield put(duplicateTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for duplicateTopUp, error', e);
    if (e?.response?.data) {
      yield put(duplicateTopUpFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteTopUp({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/top-up/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteTopUp, error', e);
    if (e?.response?.data) {
      yield put(deleteTopUpFailure(catchResponseMessages(e)));
    }
  }
}

function* updateGlobalSettings({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/update-global-settings`, payload)
    );
    if (response?.status === 200) {
      yield put(updateRetailPlanGlobalSettingsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateGlobalSettings, error', e);
    if (e?.response?.data) {
      yield put(
        updateRetailPlanGlobalSettingsFailure(catchResponseMessages(e))
      );
    }
  }
}

function* toggleTopUpEnabled({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/toggle-top-up/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(toggleTopUpSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateRetailPlan, error', e);
    if (e?.response?.data) {
      yield put(toggleTopUpFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createRetailPlanRequest, createRetailPlan);
  yield takeLatest(getRetailPlanRequest, getRetailPlan);
  yield takeLatest(getRetailPlanByIdRequest, getRetailPlanById);
  yield takeLatest(updateRetailPlanRequest, updateRetailPlan);
  yield takeLatest(setPlansSequencesRequest, setPlansSequences);
  yield takeLatest(toggleRetailRequest, toggleRetailPlanEnabled);
  yield takeLatest(duplicatePlanRequest, duplicatePlan);
  yield takeLatest(makeDefaultRetailPlanRequest, makePlanDefault);
  yield takeLatest(getRetailPlanSelectOptionsRequest, planSelectOptions);
  yield takeLatest(createTopUpRequest, createTopUp);
  yield takeLatest(getTopUpListRequest, topUpList);
  yield takeLatest(getTopUpRequest, topUpData);
  yield takeLatest(updateTopUpRequest, updateTopUp);
  yield takeLatest(duplicateTopUpRequest, duplicateTopUp);
  yield takeLatest(deleteTopUpRequest, deleteTopUp);
  yield takeLatest(updateRetailPlanGlobalSettingsRequest, updateGlobalSettings);
  yield takeLatest(toggleTopUpRequest, toggleTopUpEnabled);
}
