import { handleActions } from 'redux-actions';
import {
  createRetailPlanRequest,
  createRetailPlanSuccess,
  createRetailPlanFailure,
  getRetailPlanRequest,
  getRetailPlanSuccess,
  getRetailPlanFailure,
  getRetailPlanByIdRequest,
  getRetailPlanByIdSuccess,
  getRetailPlanByIdFailure,
  updateRetailPlanRequest,
  updateRetailPlanSuccess,
  updateRetailPlanFailure,
  setPlansSequencesRequest,
  setPlansSequencesSuccess,
  setPlansSequencesFailure,
  toggleRetailRequest,
  toggleRetailSuccess,
  toggleRetailFailure,
  duplicatePlanRequest,
  duplicatePlanFailure,
  duplicatePlanSuccess,
  makeDefaultRetailPlanRequest,
  makeDefaultRetailPlanSuccess,
  makeDefaultRetailPlanFailure,
  clearRetailPlanData,
  getRetailPlanSelectOptionsRequest,
  getRetailPlanSelectOptionsSuccess,
  getRetailPlanSelectOptionsFailure,
  createTopUpRequest,
  createTopUpFailure,
  createTopUpSuccess,
  getTopUpListRequest,
  getTopUpListSuccess,
  getTopUpListFailure,
  getTopUpRequest,
  getTopUpSuccess,
  getTopUpFailure,
  clearTopUpData,
  updateTopUpRequest,
  updateTopUpSuccess,
  updateTopUpFailure,
  duplicateTopUpRequest,
  duplicateTopUpSuccess,
  duplicateTopUpFailure,
  deleteTopUpRequest,
  deleteTopUpSuccess,
  deleteTopUpFailure,
  updateRetailPlanGlobalSettingsRequest,
  updateRetailPlanGlobalSettingsSuccess,
  updateRetailPlanGlobalSettingsFailure,
  toggleTopUpRequest,
  toggleTopUpSuccess,
  toggleTopUpFailure,
} from './actions';

const initialState = {
  errorMessage: '',
  successMessage: '',
  allRetailPlan: [],
  retailPlanCount: 0,
  isCreateRetail: false,
  isCreateRetailSuccess: false,
  isCreateRetailFailure: false,
  isGetRetail: true,
  isGetRetailSuccess: false,
  isGetRetailFailure: false,
  isGetRetailPlanById: true,
  isGetRetailPlanByIdSuccess: false,
  isGetRetailPlanByIdFailure: false,
  retailPlan: {},
  isUpdateRetail: false,
  isUpdateRetailSuccess: false,
  isUpdateRetailFailure: false,
  isSetPlansSequencesRequest: false,
  isSetPlansSequencesSuccess: false,
  isSetPlansSequencesFailure: false,
  isToggleRetailRequest: false,
  isToggleRetailSuccess: false,
  isToggleRetailFailure: false,
  isDuplicatedPlanSuccess: false,
  isDuplicatedPlanFailure: false,
  isMakingPlanDefault: false,
  isMakingPlanDefaultSuccess: false,
  isMakingPlanDefaultFailure: false,
  isGettingRetailPlanSelectData: false,
  retailPlanSelectOptions: [],
  isCreatingTopUp: false,
  isCreatingTopUpSuccess: false,
  isCreatingTopUpFailure: false,
  topUpList: [],
  isGettingTopUpList: true,
  isGettingTopUpListSuccess: false,
  isGettingTopUpListFailure: false,
  topUpCount: 0,
  isGettingTopUp: true,
  isGettingTopUpSuccess: false,
  isGettingTopUpFailure: false,
  topUpData: {},
  isUpdatingTopUp: false,
  isUpdatingTopUpSuccess: false,
  isUpdatingTopUpFailure: false,
  isDuplicatingTopUp: false,
  isDuplicatingTopUpSuccess: false,
  isDuplicatingTopUpFailure: false,
  isDeletingTopUp: false,
  isDeletingTopUpSuccess: false,
  isDeletingTopUpFailure: false,
  isToggleTopUpRequest: false,
  isToggleTopUpSuccess: false,
  isToggleTopUpFailure: false,
  isUpdatingRetailGlobalSettings: false,
  isUpdatingRetailGlobalSettingsSuccess: false,
  isUpdatingRetailGlobalSettingsFailure: false,
};

const reducer = handleActions(
  {
    // Create
    [createRetailPlanRequest]: (state) => ({
      ...state,
      isCreateRetail: true,
      isCreateRetailSuccess: false,
      isCreateRetailFailure: false,
    }),
    [createRetailPlanSuccess]: (state, { payload }) => ({
      ...state,
      isCreateRetail: false,
      isCreateRetailSuccess: true,
      isCreateRetailFailure: false,
      allRetailPlan: [payload.data, ...state.allRetailPlan],
      successMessage: payload.message,
    }),
    [createRetailPlanFailure]: (state, { payload }) => ({
      ...state,
      isCreateRetail: false,
      isCreateRetailSuccess: false,
      isCreateRetailFailure: true,
      errorMessage: payload,
    }),
    //  Get
    [getRetailPlanRequest]: (state) => ({
      ...state,
      isGetRetail: true,
      isGetRetailSuccess: false,
      isGetRetailFailure: false,
      retailPlanCount: 0,
    }),
    [getRetailPlanSuccess]: (state, { payload }) => ({
      ...state,
      isGetRetail: false,
      isGetRetailSuccess: true,
      isGetRetailFailure: false,
      allRetailPlan: payload.data.rows,
      retailPlanCount: payload.data.count,
    }),
    [getRetailPlanFailure]: (state) => ({
      ...state,
      isGetRetail: false,
      isGetRetailSuccess: false,
      isGetRetailFailure: true,
    }),
    //  Get By ID
    [getRetailPlanByIdRequest]: (state) => ({
      ...state,
      isGetRetailPlanById: true,
      isGetRetailPlanByIdSuccess: false,
      isGetRetailPlanByIdFailure: false,
    }),
    [getRetailPlanByIdSuccess]: (state, { payload }) => ({
      ...state,
      isGetRetailPlanById: false,
      isGetRetailPlanByIdSuccess: true,
      retailPlan: payload.data,
    }),
    [getRetailPlanByIdFailure]: (state, { payload }) => ({
      ...state,
      isGetRetailPlanById: false,
      isGetRetailPlanByIdFailure: true,
      errorMessage: payload,
    }),
    //  Update
    [updateRetailPlanRequest]: (state) => ({
      ...state,
      isUpdateRetail: true,
      isUpdateRetailSuccess: false,
      isUpdateRetailFailure: false,
    }),
    [updateRetailPlanSuccess]: (state, { payload }) => {
      const updatedRetailPlan = payload.data;
      return {
        ...state,
        isUpdateRetail: false,
        isUpdateRetailSuccess: true,
        isUpdateRetailFailure: false,
        allRetailPlan: state.allRetailPlan.map((plan) =>
          plan.id === updatedRetailPlan.id ? updatedRetailPlan : plan
        ),
        successMessage: payload.message,
      };
    },
    [updateRetailPlanFailure]: (state, { payload }) => ({
      ...state,
      isUpdateRetail: false,
      isUpdateRetailSuccess: false,
      isUpdateRetailFailure: true,
      errorMessage: payload,
    }),

    [setPlansSequencesRequest]: (state) => ({
      ...state,
      isSetPlansSequencesRequest: true,
      isSetPlansSequencesSuccess: false,
      isSetPlansSequencesFailure: false,
    }),
    [setPlansSequencesSuccess]: (state, { payload }) => ({
      ...state,
      isSetPlansSequencesRequest: false,
      isSetPlansSequencesSuccess: true,
      allRetailPlan: state.allRetailPlan.map((plan) => {
        for (const item of payload.data) {
          if (item?.id === plan.id) {
            plan.sequences = item?.sequences;
          }
        }

        return plan;
      }),
      successMessage: payload.message,
    }),
    [setPlansSequencesFailure]: (state, { payload }) => ({
      ...state,
      isSetPlansSequencesRequest: false,
      isSetPlansSequencesFailure: true,
      errorMessage: payload,
    }),

    //  Toggle Update
    [toggleRetailRequest]: (state) => ({
      ...state,
      isToggleRetailRequest: false,
      isToggleRetailSuccess: false,
      isToggleRetailFailure: false,
    }),
    [toggleRetailSuccess]: (state, { payload }) => {
      const { id } = payload.data;
      return {
        ...state,
        isToggleRetailRequest: false,
        isToggleRetailSuccess: true,
        isToggleRetailFailure: false,
        allRetailPlan: state.allRetailPlan.map((plan) =>
          plan.id === id ? { ...plan, isActive: !plan.isActive } : plan
        ),
      };
    },
    [toggleRetailFailure]: (state) => ({
      ...state,
      isToggleRetailRequest: false,
      isToggleRetailSuccess: false,
      isToggleRetailFailure: true,
    }),
    [duplicatePlanRequest]: (state) => ({
      ...state,
      isDuplicatedPlanSuccess: false,
      isDuplicatedPlanFailure: false,
    }),
    [duplicatePlanSuccess]: (state, { payload }) => ({
      ...state,
      isDuplicatedPlanSuccess: true,
      successMessage: payload.message,
      allRetailPlan: [...state.allRetailPlan, payload.data],
      retailPlanCount: state.retailPlanCount + 1,
    }),
    [duplicatePlanFailure]: (state, { payload }) => ({
      ...state,
      isDuplicatedPlanFailure: true,
      errorMessage: payload,
    }),
    [makeDefaultRetailPlanRequest]: (state) => ({
      ...state,
      isMakingPlanDefault: true,
      isMakingPlanDefaultSuccess: false,
      isMakingPlanDefaultFailure: false,
    }),
    [makeDefaultRetailPlanSuccess]: (state, { payload }) => {
      const retailPlansCopy = [...state.allRetailPlan];
      const index = retailPlansCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        retailPlansCopy.map((i) => {
          i.isMain = false;
          return i;
        });
        retailPlansCopy[index] = payload.data;
      }
      return {
        ...state,
        isMakingPlanDefault: false,
        isMakingPlanDefaultSuccess: true,
        successMessage: payload.message,
        allRetailPlan: retailPlansCopy,
      };
    },
    [makeDefaultRetailPlanFailure]: (state, { payload }) => ({
      ...state,
      isMakingPlanDefault: false,
      isMakingPlanDefaultFailure: true,
      errorMessage: payload,
    }),
    [clearRetailPlanData]: (state) => ({
      ...state,
      retailPlan: {},
    }),
    [getRetailPlanSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingRetailPlanSelectData: true,
    }),
    [getRetailPlanSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingRetailPlanSelectData: false,
      retailPlanSelectOptions: payload.data,
      successMessage: payload.message,
    }),
    [getRetailPlanSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingRetailPlanSelectData: false,
      errorMessage: payload,
    }),
    [createTopUpRequest]: (state) => ({
      ...state,
      isCreatingTopUp: true,
      isCreatingTopUpSuccess: false,
      isCreatingTopUpFailure: false,
    }),
    [createTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingTopUp: false,
      isCreatingTopUpSuccess: true,
      successMessage: payload.message,
      topUps: [payload.data, ...state.topUpList],
      topUpCount: state.topUpCount + 1,
    }),
    [createTopUpFailure]: (state, { payload }) => ({
      ...state,
      isCreatingTopUp: false,
      isCreatingTopUpFailure: true,
      errorMessage: payload,
    }),
    [getTopUpListRequest]: (state) => ({
      ...state,
      isGettingTopUpList: true,
      isGettingTopUpListSuccess: false,
      isGettingTopUpListFailure: false,
    }),
    [getTopUpListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTopUpList: false,
      isGettingTopUpListSuccess: true,
      topUpList: payload.data.rows,
      topUpCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getTopUpListFailure]: (state, { payload }) => ({
      ...state,
      isGettingTopUpList: false,
      isGettingTopUpListFailure: true,
      errorMessage: payload,
    }),
    [getTopUpRequest]: (state) => ({
      ...state,
      isGettingTopUp: true,
      isGettingTopUpSuccess: false,
      isGettingTopUpFailure: false,
    }),
    [getTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTopUp: false,
      isGettingTopUpSuccess: true,
      successMessage: payload.message,
      topUpData: payload.data,
    }),
    [getTopUpFailure]: (state, { payload }) => ({
      ...state,
      isGettingTopUp: false,
      isGettingTopUpFailure: true,
      errorMessage: payload,
    }),
    [clearTopUpData]: (state) => ({
      ...state,
      topUpData: {},
    }),
    [updateTopUpRequest]: (state) => ({
      ...state,
      isUpdatingTopUp: true,
      isUpdatingTopUpSuccess: false,
      isUpdatingTopUpFailure: false,
    }),
    [updateTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingTopUp: false,
      isUpdatingTopUpSuccess: true,
      successMessage: payload.message,
    }),
    [updateTopUpFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTopUp: false,
      isUpdatingTopUpFailure: true,
      errorMessage: payload,
    }),
    [duplicateTopUpRequest]: (state) => ({
      ...state,
      isDuplicatingTopUp: true,
      isDuplicatingTopUpSuccess: false,
      isDuplicatingTopUpFailure: false,
    }),
    [duplicateTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isDuplicatingTopUp: false,
      isDuplicatingTopUpSuccess: true,
      successMessage: payload.message,
      topUpList: [...state.topUpList, payload.data],
      topUpCount: state.topUpCount + 1,
    }),
    [duplicateTopUpFailure]: (state, { payload }) => ({
      ...state,
      isDuplicatingTopUp: false,
      isDuplicatingTopUpFailure: true,
      errorMessage: payload,
    }),
    [deleteTopUpRequest]: (state) => ({
      ...state,
      isDeletingTopUp: true,
      isDeletingTopUpSuccess: false,
      isDeletingTopUpFailure: false,
    }),
    [deleteTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingTopUp: false,
      isDeletingTopUpSuccess: true,
      successMessage: payload.message,
      topUpList: state.topUpList.filter((i) => i.id !== +payload.data),
      topUpCount: state.topUpCount - 1,
    }),
    [deleteTopUpFailure]: (state, { payload }) => ({
      ...state,
      isDeletingTopUp: false,
      isDeletingTopUpFailure: true,
      errorMessage: payload,
    }),
    [toggleTopUpRequest]: (state) => ({
      ...state,
      isToggleTopUpRequest: true,
      isToggleTopUpSuccess: false,
      isToggleTopUpFailure: false,
    }),
    [toggleTopUpSuccess]: (state, { payload }) => ({
      ...state,
      isToggleTopUpRequest: false,
      isToggleTopUpSuccess: true,
      topUpList: state.topUpList.map((topUp) =>
        topUp.id === payload.data.id ? payload.data : topUp
      ),
      successMessage: payload.message,
    }),
    [toggleTopUpFailure]: (state, { payload }) => ({
      ...state,
      isToggleTopUpRequest: false,
      isToggleTopUpFailure: true,
      errorMessage: payload,
    }),
    [updateRetailPlanGlobalSettingsRequest]: (state) => ({
      ...state,
      isUpdatingRetailGlobalSettings: true,
      isUpdatingRetailGlobalSettingsSuccess: false,
      isUpdatingRetailGlobalSettingsFailure: false,
    }),
    [updateRetailPlanGlobalSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingRetailGlobalSettings: false,
      isUpdatingRetailGlobalSettingsSuccess: true,
      successMessage: payload.message,
    }),
    [updateRetailPlanGlobalSettingsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingRetailGlobalSettings: false,
      isUpdatingRetailGlobalSettingsFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
