import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosApiInstance, config } from 'custom-configs';
import { catchResponseMessages } from 'utils/methods';
import {
  deleteLogoFailure,
  deleteLogoRequest,
  deleteLogoSuccess,
  deleteFaviconRequest,
  deleteFaviconSuccess,
  deleteFaviconFailure,
  getAPIKeysFailure,
  getAPIKeysRequest,
  getAPIKeysSuccess,
  getSiteSettingsFailure,
  getSiteSettingsRequest,
  getSiteSettingsSuccess,
  getSiteThemeRequest,
  getSiteThemeSuccess,
  getSiteThemeFailure,
  updateAPIKeysFailure,
  updateAPIKeysRequest,
  updateAPIKeysSuccess,
  updateSiteSettingsFailure,
  updateSiteSettingsRequest,
  updateSiteSettingsSuccess,
  updateTermsSuccess,
  updateTermsFailure,
  updateTermsRequest,
  getTermsSuccess,
  getTermsFailure,
  getTermsRequest,
  getAllWebhooksSuccess,
  getAllWebhooksFailure,
  getAllWebhooksRequest,
  syncWebhooksSuccess,
  syncWebhooksFailure,
  syncWebhooksRequest,
  addWebhookSuccess,
  addWebhookFailure,
  addWebhookRequest,
  deleteWebhookSuccess,
  deleteWebhookFailure,
  deleteWebhookRequest,
  changeThemeModeSuccess,
  changeThemeModeFailure,
  changeThemeModeRequest,
  createMainBannerSuccess,
  createMainBannerFailure,
  createMainBannerRequest,
  getMainBannerRequest,
  getMainBannerSuccess,
  getMainBannerFailure,
} from './actions';

const URL = `${config.TENANT}/settings`;

export function* updateSiteSettings({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/site-settings/${payload.id}`, payload.data)
    );

    if (response?.status === 200) {
      yield put(updateSiteSettingsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateSiteSettings, error', e);
    if (e?.response?.data) {
      yield put(updateSiteSettingsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getSiteSettings({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/site-settings/${payload.id}`)
    );

    if (response?.status === 200) {
      yield put(getSiteSettingsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSiteSettings, error', e);
    if (e?.response?.data) {
      yield put(getSiteSettingsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getSiteTheme() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${config.TENANT}/site-theme`)
    );

    if (response?.status === 200) {
      yield put(getSiteThemeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSiteTheme, error', e);
    if (e?.response?.data) {
      yield put(getSiteThemeFailure(catchResponseMessages(e)));
    }
  }
}

export function* deleteLogo({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/delete-logo/${payload.id}/${payload.userId}`
      )
    );

    if (response?.status === 200) {
      yield put(deleteLogoSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteLogo, error', e);
    if (e?.response?.data) {
      yield put(deleteLogoFailure(catchResponseMessages(e)));
    }
  }
}

export function* deleteFavicon({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/delete-favicon/${payload.id}/${payload.userId}`
      )
    );

    if (response?.status === 200) {
      yield put(deleteFaviconSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteFavicon, error', e);
    if (e?.response?.data) {
      yield put(deleteFaviconFailure(catchResponseMessages(e)));
    }
  }
}
export function* updateAPIKeys({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/api-keys/${payload.userId}`, payload.data)
    );

    if (response?.status === 200) {
      yield put(updateAPIKeysSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateAPIKeys, error', e);
    if (e?.response?.data) {
      yield put(updateAPIKeysFailure(catchResponseMessages(e)));
    }
  }
}
export function* getKeys({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/keys/${payload.userId}${payload.query}`)
    );

    if (response?.status === 200) {
      yield put(getAPIKeysSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getKeys, error', e);
    if (e?.response?.data) {
      yield put(getAPIKeysFailure(catchResponseMessages(e)));
    }
  }
}

export function* updateTerms({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/terms`, payload)
    );
    if (response.status === 200) {
      yield put(updateTermsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateTerms, error', e);
    if (e.response.data) {
      yield put(updateTermsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getTerms({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/terms${payload}`)
    );
    if (response.status === 200) {
      yield put(getTermsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getTerms, error', e);
    if (e.response.data) {
      yield put(getTermsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getAllWebhooks({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/webhooks${payload}`)
    );
    if (response.status === 200) {
      yield put(getAllWebhooksSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAllWebhooks, error', e);
    if (e.response.data) {
      yield put(getAllWebhooksFailure(catchResponseMessages(e)));
    }
  }
}

export function* syncWebhooks({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/webhooks-sync`)
    );
    if (response.status === 200) {
      yield put(syncWebhooksSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for syncWebhooks, error', e);
    if (e.response.data) {
      yield put(syncWebhooksFailure(catchResponseMessages(e)));
    }
  }
}

export function* createWebhook({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/add-webhook`, payload)
    );
    if (response.status === 200) {
      yield put(addWebhookSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createWebhook, error', e);
    if (e.response.data) {
      yield put(addWebhookFailure(catchResponseMessages(e)));
    }
  }
}

export function* deleteWebhook({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-webhook/${payload.id}`)
    );
    if (response.status === 200) {
      yield put(deleteWebhookSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteWebhook, error', e);
    if (e.response.data) {
      yield put(deleteWebhookFailure(catchResponseMessages(e)));
    }
  }
}

export function* changeThemeMode({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/change-theme-mode`, payload)
    );
    if (response.status === 200) {
      yield put(changeThemeModeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for changeThemeMode, error', e);
    if (e.response.data) {
      yield put(changeThemeModeFailure(catchResponseMessages(e)));
    }
  }
}

export function* createMainBanner({ payload }) {
  try {
    console.log('payload', payload);
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-main-banner`, payload)
    );

    if (response?.status === 200) {
      yield put(createMainBannerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createMainBanner, error', e);
    if (e?.response?.data) {
      yield put(createMainBannerFailure(catchResponseMessages(e)));
    }
  }
}

export function* getMainBanner() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/get-main-banner`)
    );

    if (response?.status === 200) {
      yield put(getMainBannerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getMainBanner, error', e);
    if (e?.response?.data) {
      yield put(getMainBannerFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(updateSiteSettingsRequest, updateSiteSettings);
  yield takeLatest(getSiteSettingsRequest, getSiteSettings);
  yield takeLatest(getSiteThemeRequest, getSiteTheme);
  yield takeLatest(deleteLogoRequest, deleteLogo);
  yield takeLatest(deleteFaviconRequest, deleteFavicon);
  yield takeLatest(updateAPIKeysRequest, updateAPIKeys);
  yield takeLatest(getAPIKeysRequest, getKeys);
  yield takeLatest(updateTermsRequest, updateTerms);
  yield takeLatest(getTermsRequest, getTerms);
  yield takeLatest(getAllWebhooksRequest, getAllWebhooks);
  yield takeLatest(syncWebhooksRequest, syncWebhooks);
  yield takeLatest(addWebhookRequest, createWebhook);
  yield takeLatest(deleteWebhookRequest, deleteWebhook);
  yield takeLatest(changeThemeModeRequest, changeThemeMode);
  yield takeLatest(createMainBannerRequest, createMainBanner);
  yield takeLatest(getMainBannerRequest, getMainBanner);
}
